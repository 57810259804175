import { EyeTwoTone, PlusOutlined } from "@ant-design/icons";
import "../../../css/styles.css";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  notification,
} from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";
import React, { Fragment, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import BillCreateForm from "./BillCreateForm";
import moment, { Moment } from "moment";
import {
  BillInfo,
  BillUpdateStatusDto,
  CreateBillDto,
  TransactionStatus,
} from "../../../types/bill";
import { ProColumns, ProTable } from "@ant-design/pro-components";
import { DepartmentEnum, TransactionType } from "../../../types/transaction";
import {
  createBill,
  getBillPrinted,
  searchBill,
  updateStatusBill,
} from "../../../services/bill";
import BillPrintedForm from "./BillPrintedForm";
import {
  departmentValueEnums,
  listDepartmentOption,
} from "../../../utils/utils";
import { UserInfoResDto, getAllUsers } from "../../../services/user";
const { confirm } = Modal;
const { RangePicker } = DatePicker;
type BillListProps = {
  intl: any;
};
const BillList: React.FC<BillListProps> = ({ intl }) => {
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
  const [isModalPrintVisible, setIsModalPrintVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [selectedBill, setSelectedBill] = useState<BillInfo>();
  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const [searchName, setSearchName] = useState<string>();
  const [selectedDepartment, setSelectedDepartment] = useState<
    DepartmentEnum | undefined
  >(undefined);

  const [headerTitle, setHeaderTitle] = useState("Danh sách hoá đơn");

  const [isOpenModalForEdit, setIsOpenModalForEdit] = useState(false);

  const [selectedRange, setSelectedRange] = useState<
    [Moment | null, Moment | null]
  >([null, null]);
  const handleRangeChange = (
    dates: [Moment | null, Moment | null],
    dateStrings: [string, string]
  ) => {
    setSelectedRange(dates);
  };

  const [listUsers, setListUsers] = useState<UserInfoResDto[]>([]);
  const [selectedQueryUser, setSelectedQueryUser] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    getAllUsers().then((res) => {
      setListUsers(res.data.users);
    });
  }, []);

  const handleGetAllBillAndOpenForm = (createdBillId: number) => {
    searchBill({
      offset: 0,
      limit: 5,
    }).then((res) => {
      const listBill = res.data.items;

      if (createdBillId) {
        setSelectedBill(listBill.find((bill) => bill.id === createdBillId));
        setIsModalCreateVisible(true);
        setIsOpenModalForEdit(true);
      }
    });
  };

  const handleDoneBill = (id: number) => {
    handleUpdateBillStatus({ id, status: TransactionStatus.DONE });
  };

  const handleRejectBill = (id: number) => {
    handleUpdateBillStatus({ id, status: TransactionStatus.REJECTED });
  };

  // Helper function to generate the file name
  const generateFileName = (id: number, name?: string): string => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `HĐ_${year}_${month}_${day}_${id}_${name}.pdf`;
  };

  // Helper function to trigger the download
  const triggerDownload = (url: string, fileName: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePrintBill = async (id: number) => {
    if (selectedBill && selectedBill.id === id) {
      if (selectedBill?.isPrinted === false) {
        getBillPrinted(selectedBill.id)
          .then((response) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/pdf" })
            );
            // Use helper functions for file name generation and download
            const fileName = generateFileName(id, selectedBill.studentFullName);
            triggerDownload(url, fileName);
            setPdfUrl(url);
            setIsModalPrintVisible(true);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const handlePrint = () => {
    const iframe = document.getElementById("my-iframe") as HTMLIFrameElement;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.print();
    }
  };

  const handleUpdateBillStatus = (values: BillUpdateStatusDto) => {
    confirm({
      title:
        "Bạn có chắc chắn không, hành động này sẽ không được chỉnh sửa sau khi thay đổi",
      okText: "Có",
      cancelText: "Không",
      onOk() {
        if (values.id) {
          updateStatusBill(values)
            .then(() => {
              notification.success({
                message: "Cập nhật thành công",
                description: "Đã cập nhật trạng thái hoá đơn",
              });
              setIsModalCreateVisible(false);
              setIsOpenModalForEdit(false);
              setRefreshCounter((prev) => prev + 1);
            })
            .catch((err) => {
              notification.error({
                message: "Cập nhật thất bại",
                description: "Cập nhật trạng thái hoá đơn thất bại",
              });
            });
        }
      },
    });
  };

  // // Function to convert UTC time to the desired time zone (Asia/Ho_Chi_Minh)
  // const convertToTimeZone = (updatedAt: string) => {
  //   // Convert to Date object
  //   const date = new Date(updatedAt);

  //   // Convert to Asia/Ho_Chi_Minh time zone (UTC+7)
  //   const options = {
  //     timeZone: "Asia/Ho_Chi_Minh",
  //     year: "numeric",
  //     month: "numeric",
  //     day: "numeric",
  //     hour: "numeric",
  //     minute: "numeric",
  //     second: "numeric",
  //     timeZoneName: "short",
  //   };
  //   return date.toLocaleString("en-US", options);
  // };

  const handleCreateBill = (values: CreateBillDto) => {
    createBill(values)
      .then((res) => {
        notification.success({
          message: intl.formatMessage({
            id: "noti.create.complete",
          }),
          description: intl.formatMessage({
            id: "detail.create.bill.successfully",
            defaultMessage: "Tạo hoá đơn thành công",
          }),
        });
        handleGetAllBillAndOpenForm(res.data.id);
        setRefreshCounter((prev) => prev + 1);
        setIsModalCreateVisible(false);
      })
      .catch((e) => {
        notification.error({
          message: intl.formatMessage({
            id: "noti.create.fail",
          }),
          description: "Tạo hoá đơn thất bại",
        });
      });
  };
  const columns: ProColumns<BillInfo>[] = [
    {
      title: "ID",
      hideInTable: false,
      dataIndex: "id",
      renderText: (text) => {
        return `${text.toString().padStart(7, "0")}`;
      },
      copyable: true,
      width: 70,
    },
    {
      title: "Loại HĐ",
      dataIndex: "type",
      ellipsis: true,
      width: "50px",
      renderText: (text) => {
        let newText = "";
        switch (text) {
          case TransactionType.ADVANCES_TO_EMPLOYEE:
            newText = "Tạm ứng";
            break;
          case TransactionType.PRIMARY_TUITION:
            newText = "Thu học phí tiểu học (không có ds lớp)";
            break;
          case TransactionType.OTHER_EXPENSE:
            newText = "Khoản chi khác";
            break;
          case TransactionType.OTHER_INCOME:
            newText = "Khoản thu khác";
            break;
          case TransactionType.TEACHER_PAYMENT:
            newText = "Trả tiền giáo viên";
            break;
          case TransactionType.TUITION:
            newText = "Học phí";
            break;

          default:
            break;
        }
        return <Tag>{newText}</Tag>;
      },
    },
    {
      title: "Trạng Thái",
      dataIndex: "status",
      renderText: (text) => {
        let newText = "";
        let color = "blue";
        switch (text) {
          case TransactionStatus.DRAFT:
            color = "yellow";
            newText = "Chưa xác nhận";
            break;
          case TransactionStatus.DONE:
            newText = "Đã xác nhận";
            color = "green";
            break;
          case TransactionStatus.REJECTED:
            color = "red";
            newText = "Huỷ";
            break;
          default:
            break;
        }
        return <Tag color={color}>{newText}</Tag>;
      },
      width: 80,
    },
    {
      title: intl.formatMessage({
        id: "department",
      }),
      dataIndex: "department",
      ellipsis: true,
      width: 60,
      valueEnum: departmentValueEnums,
    },
    {
      title: "Chi tiết",
      dataIndex: "detail",
      ellipsis: true,
      width: 50,
    },
    {
      title: "Tên HS",
      dataIndex: "studentFullName",
      ellipsis: true,
      width: 175,
    },
    // {
    //   title: "Ghi chú",
    //   dataIndex: "note",
    //   ellipsis: true,
    //   width: 150,
    // },
    {
      title: "Số tiền",
      dataIndex: "totalAmount",
      ellipsis: true,
      renderText: (text) => {
        return `${text
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} VND`;
      },
      width: "10%",
    },
    // {
    //   title: "Ngày lập hoá đơn",
    //   dataIndex: "createdAt",
    //   ellipsis: true,
    //   valueType: "dateTime",
    //   width: "10%",
    // },
    {
      title: "Ngày chỉnh hoá đơn",
      dataIndex: "updatedAt",
      ellipsis: true,
      renderText: (updatedAt: string) => {
        const utcDate = new Date(updatedAt);
        const dates = utcDate
          .toLocaleString("vi", {
            timeZone: "Asia/Ho_Chi_Minh",
          })
          .split(" ");
        return (
          <span>
            <span style={{ color: "gray" }}>{dates[1]}</span>{" "}
            <span style={{ fontWeight: "bold", color: "black" }}>
              {dates[0]}
            </span>
          </span>
        );
      },

      width: 150,
    },
    {
      title: "Chỉnh sửa bởi",
      dataIndex: "updatedBy",
      ellipsis: true,
      width: 50,
    },
    {
      title: intl.formatMessage({
        id: "action",
      }),
      key: "action",
      width: 55,
      render: (text: any, record: any) => (
        <Space>
          <Button
            type="text"
            className="custom-btn"
            onClick={() => {
              setSelectedBill(record);
              setIsModalCreateVisible(true);
              setIsOpenModalForEdit(true);
            }}
          >
            <EyeTwoTone
              style={{ fontSize: "15px", width: "15px", height: "15px" }}
            />
          </Button>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    const uppercaseValue = value.toUpperCase();
    setSearchName(uppercaseValue);
  };

  const vietnameseMonthNames = [
    "T1",
    "T2",
    "T3",
    "T4",
    "T5",
    "T6",
    "T7",
    "T8",
    "T",
    "T10",
    "T11",
    "T12",
  ];

  const dateRender = (current: moment.Moment) => {
    return <div className="ant-picker-cell-inner">{current.date()}</div>;
  };

  const monthCellRender = (date: moment.Moment) => {
    const month = date.month();
    return (
      <div className="ant-picker-cell-inner">{vietnameseMonthNames[month]}</div>
    );
  };

  const panelRender = (panelNode: React.ReactNode) => {
    return <div className="custom-panel">{panelNode}</div>;
  };

  return (
    <Fragment>
      <ProTable<
        BillInfo,
        {
          name?: string;
          department?: string;
          pageSize?: number;
          current?: number;
          refreshCounter: number;
          selectedUser?: number;
          selectedDateRange?: [moment.Moment | null, moment.Moment | null];
        }
      >
        headerTitle={headerTitle}
        columns={columns}
        rowKey="id"
        search={false}
        pagination={{ pageSize: 10 }}
        options={false}
        params={{
          name: searchName,
          refreshCounter: refreshCounter,
          department: selectedDepartment,
          selectedUser: selectedQueryUser,
          selectedDateRange: selectedRange,
        }}
        request={async (params, sorter, filter) => {
          const {
            name,
            pageSize,
            department,
            current,
            selectedUser,
            selectedDateRange,
          } = params;

          let startDate: Date | undefined = undefined;
          let endDate: Date | undefined = undefined;
          if (
            selectedDateRange &&
            selectedDateRange[0] &&
            selectedDateRange[1]
          ) {
            startDate = selectedDateRange[0].toDate();
            endDate = selectedDateRange[1].toDate();
          }
          if (current !== undefined && pageSize !== undefined) {
            const valueNew = await searchBill({
              startDate,
              endDate,
              userId: selectedUser,
              name: name || undefined,
              department: department || undefined,
              offset: (current - 1) * pageSize,
              limit: pageSize,
            });

            if (!selectedUser) {
              setHeaderTitle(`Danh sách hoá đơn`);
            } else {
              setHeaderTitle(
                `Danh sách hoá đơn - Tổng thu ${valueNew.data.totalAmount
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} VND`
              );
            }

            return {
              data: valueNew.data.items,
              total: valueNew.data.total,
              success: true,
            };
          }
          return {
            data: [],
            total: 0,
            success: false,
          };
        }}
        // toolbar={{
        //   search: {
        //     allowClear: true,
        //     placeholder: "Tìm tên học sinh",
        //     onSearch: handleSearch,
        //   },
        // }}
        toolBarRender={() => [
          <Row gutter={16}>
            <Col span={13} style={{ marginRight: "8px" }}>
              <Row style={{ marginBottom: "8px" }}>
                <RangePicker
                  allowClear
                  format="DD-MM-YYYY HH:mm:ss"
                  dateRender={dateRender}
                  monthCellRender={monthCellRender}
                  panelRender={panelRender}
                  locale={locale}
                  style={{ height: "40px", borderRadius: "6px" }}
                  onChange={(values, formatString) => {
                    if (values?.[0] && values?.[1])
                      handleRangeChange(
                        [values?.[0]?.clone(), values?.[1]?.clone()],
                        formatString
                      );
                    else {
                      setSelectedRange([null, null]);
                    }
                  }}
                  value={selectedRange}
                  // renderExtraFooter={() => "extra footer"}
                  showTime
                />
              </Row>

              <Row>
                <Input.Search
                  enterButton={false}
                  className="search-input"
                  allowClear
                  placeholder={"Tìm tên học sinh"}
                  onSearch={handleSearch}
                />
              </Row>
            </Col>
            <Col>
              <Row style={{ marginBottom: "8px" }}>
                <Select
                  style={{ width: "100%" }}
                  allowClear
                  placeholder="Tìm theo người tạo"
                  onChange={(e) => {
                    setSelectedQueryUser(e);
                  }}
                  onClear={() => {
                    setSelectedQueryUser(undefined);
                  }}
                  onSelect={(user: any) => {
                    setSelectedQueryUser(user);
                  }}
                >
                  {listUsers.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
              <Row>
                <Select
                  style={{ width: "100%" }}
                  allowClear
                  className="search-department"
                  placeholder="Tìm theo cơ sở"
                  onChange={(e) => {
                    setSelectedDepartment(e);
                  }}
                  onClear={() => {
                    setSelectedDepartment(undefined);
                  }}
                  onSelect={(department: any) => {
                    setSelectedDepartment(department);
                  }}
                >
                  {listDepartmentOption.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
            </Col>
            <Col span={3}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsModalCreateVisible(true)}
              >
                {intl.formatMessage({
                  id: "add",
                  defaultMessage: "Add",
                })}
              </Button>
            </Col>
          </Row>,
        ]}
      />

      {isModalCreateVisible && (
        <BillCreateForm
          isEdited={isOpenModalForEdit}
          billId={selectedBill?.id}
          visible={isModalCreateVisible}
          onCancel={() => {
            setIsModalCreateVisible(false);
            setIsOpenModalForEdit(false);
          }}
          handleDoneBill={handleDoneBill}
          handleRejectBill={handleRejectBill}
          handlePrintBill={handlePrintBill}
          onConfirm={handleCreateBill}
        />
      )}

      {isModalPrintVisible && (
        <BillPrintedForm
          visible={isModalPrintVisible}
          onCancel={() => {
            setIsModalPrintVisible(false);
            setPdfUrl("");
          }}
          pdfUrl={pdfUrl}
          onConfirm={handlePrint}
        />
      )}
    </Fragment>
  );
};

export default injectIntl(BillList);
